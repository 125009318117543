<template>
  <div class="bg-white border bd-e4 px-3">
    <HeaderWindow @onClose="onClose" :title="title" :show_calll="true" />
    <div class="bg-white pt-3">
      <PersonProfile
        v-if="disease"
        :person="disease && disease.person"
        :monitor_stats="monitor_stats"
        :disease="disease"
        @showModalAppt="showModalAppt"
      ></PersonProfile>
    </div>
    <TypeBar
      v-if="disease"
      :showing="show"
      :person_id="person_id"
      :disease="disease"
    />
    <div class="row mb-3" v-if="topics && topics.count">
      <div class="col-md-6"></div>
      <div class="col-md-6 d-flex align-items-center justify-content-end">
        <!-- <button
          v-if="can('create-new-appt')"
          class="bg-pri bd-pri text-white btn wd-180 d-flex justify-content-between align-items-center mr-1"
          @click="showModalAppt(true)"
        >
          <img
            src="../../../public/assets/images/icon/icon-tele-gray.svg"
            width="32"
            height="32"
            alt=""
            class="mr-2"
          >
          <span class="robo-16-700">Đặt lịch hẹn</span>
        </button>-->
        <button
          class="bg-pri bd-pri text-white btn d-flex justify-content-between align-items-center"
          @click="goToCreate"
          type="button"
        >
          <img
            src="../../../public/assets/images/icon/icon-exchange-gray.svg"
            width="32"
            height="32"
            alt
            class="mr-2"
          />
          <span class="robo-16-700">
            {{ $t("obj_health_records.lbl_create_new_exchange") }}
          </span>
        </button>
      </div>
    </div>
    <div class="">
      <div
        class="d-flex flex-wrap justify-content-start align-items-center mb-3 radius-10 cursor-pointer bg-bold-item"
        style="padding: 15px"
        @click="goToCallLog()"
      >
        <div class="left-side-item">
          <div class="icon--item bg-white">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 20C0 8.9543 8.9543 0 20 0V0C31.0457 0 40 8.9543 40 20V20C40 31.0457 31.0457 40 20 40V40C8.9543 40 0 31.0457 0 20V20Z"
                fill="#31BBC4"
              />
              <path
                d="M18.05 23.95L16.2 25.8C15.81 26.19 15.19 26.19 14.79 25.81C14.68 25.7 14.57 25.6 14.46 25.49C13.43 24.45 12.5 23.36 11.67 22.22C10.85 21.08 10.19 19.94 9.71 18.81C9.24 17.67 9 16.58 9 15.54C9 14.86 9.12 14.21 9.36 13.61C9.6 13 9.98 12.44 10.51 11.94C11.15 11.31 11.85 11 12.59 11C12.87 11 13.15 11.06 13.4 11.18C13.66 11.3 13.89 11.48 14.07 11.74L16.39 15.01C16.57 15.26 16.7 15.49 16.79 15.71C16.88 15.92 16.93 16.13 16.93 16.32C16.93 16.56 16.86 16.8 16.72 17.03C16.59 17.26 16.4 17.5 16.16 17.74L15.4 18.53C15.29 18.64 15.24 18.77 15.24 18.93C15.24 19.01 15.25 19.08 15.27 19.16C15.3 19.24 15.33 19.3 15.35 19.36C15.53 19.69 15.84 20.12 16.28 20.64C16.73 21.16 17.21 21.69 17.73 22.22C17.83 22.32 17.94 22.42 18.04 22.52C18.44 22.91 18.45 23.55 18.05 23.95Z"
                fill="white"
              />
              <path
                d="M28.97 27.33C28.97 27.61 28.92 27.9 28.82 28.18C28.79 28.26 28.76 28.34 28.72 28.42C28.55 28.78 28.33 29.12 28.04 29.44C27.55 29.98 27.01 30.37 26.4 30.62C26.39 30.62 26.38 30.63 26.37 30.63C25.78 30.87 25.14 31 24.45 31C23.43 31 22.34 30.76 21.19 30.27C20.04 29.78 18.89 29.12 17.75 28.29C17.36 28 15.37 26.22 15 25.91L17.75 22.2804C18.03 22.4904 20.4 24.5 20.61 24.61C20.66 24.63 20.72 24.66 20.79 24.69C20.87 24.72 20.95 24.73 21.04 24.73C21.21 24.73 21.34 24.67 21.45 24.56L22.21 23.81C22.46 23.56 22.7 23.37 22.93 23.25C23.16 23.11 23.39 23.04 23.64 23.04C23.83 23.04 24.03 23.08 24.25 23.17C24.47 23.26 24.7 23.39 24.95 23.56L28.26 25.91C28.52 26.09 28.7 26.3 28.81 26.55C28.91 26.8 28.97 27.05 28.97 27.33Z"
                fill="white"
              />
              <path
                d="M25 10C22.245 10 20 12.245 20 15C20 17.755 22.245 20 25 20C27.755 20 30 17.755 30 15C30 12.245 27.755 10 25 10ZM27.175 16.785C27.105 16.905 26.98 16.97 26.85 16.97C26.785 16.97 26.72 16.955 26.66 16.915L25.11 15.99C24.725 15.76 24.44 15.255 24.44 14.81V12.76C24.44 12.555 24.61 12.385 24.815 12.385C25.02 12.385 25.19 12.555 25.19 12.76V14.81C25.19 14.99 25.34 15.255 25.495 15.345L27.045 16.27C27.225 16.375 27.285 16.605 27.175 16.785Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div class="right-side-item">
          <div class="row mx-0">
            <div class="flex items-center col-md-3 mb-2 mb-md-0">
              <div class="robo-16-500 txt-black">
                {{ $t("obj_health_records.lbl_call_history") }}
                <span class="ml-1"
                  >({{ (call_logs && call_logs.total) || 0 }})</span
                >
              </div>
            </div>
            <div
              v-if="call_logs && call_logs.data"
              class="col-md-7 d-flex flex-wrap justify-content-start align-items-center mb-2 mb-md-0"
            >
              <div class="w-100" :set="(log = call_logs.data[0])">
                <p
                  class="mb-0 txt-black text-concat robo-16-500"
                  :set="(is_from = getIsFrom(call_logs.data[0]))"
                  v-if="log"
                >
                  {{ $t("obj_health_records.lbl_call") }}
                  {{
                    log && log.is_videocall
                      ? ` ${$t("obj_health_records.lbl_video")} `
                      : ` ${$t("obj_health_records.lbl_audio")} `
                  }}
                  {{
                    is_from
                      ? ` ${$t("obj_health_records.lbl_call_away")} `
                      : ` ${$t("obj_health_records.lbl_incoming_call")} `
                  }}
                  -
                  {{
                    is_from ? log.to && log.to.name : log.from && log.from.name
                  }}
                </p>
              </div>
            </div>
            <div
              class="col-md-2 d-flex flex-wrap justify-content-end align-items-center mb-2 mb-md-0"
            >
              <p
                v-if="call_logs && call_logs.data && call_logs.data[0]"
                class="mb-0 robo-16-500"
              >
                {{
                  call_logs.data[0].created_at
                    ? formatDMY(call_logs.data[0].created_at)
                    : ""
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ActivityTable
      :topics="topics"
      :person_id="disease && disease.person && disease.person.id"
      :loading="loading"
      :current_page="current_page"
      @onRefresh="getTopics"
      @onShowModalAppt="showModalAppt"
    ></ActivityTable>
  </div>
</template>
<script>
import PersonProfile from '../../components/Diseases/PersonProfile'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import TypeBar from '../../components/Diseases/TypeBar.vue'
import Thread from '../../components/Thread/Thread'
import ActivityTable from '../../components/Diseases/ActivityTable.vue'
import appUtils from '../../utils/appUtils'
export default {
  name: 'DiseasesActivity',
  components: { PersonProfile, HeaderWindow, TypeBar, Thread, ActivityTable },
  data () {
    return {
      show: 'diary',
      title: this.$t('obj_health_records.lbl_message'),
      person_id: null,
      person: null,
      is_renew: false,
      disease: null,
      diseases_id: '',
      monitor_stats: null,
      topics: [],
      loading: false,
      current_page: 1,
      call_logs: [],
      user: []
    }
  },
  watch: {
    '$route.query.t': {
      handler: function (t) {
        if (!t) return
        this.getTopics()
        this.getCallLogs()
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    let self = this
    self.diseases_id = self.$route?.params?.id
    self.user = appUtils.getLocalUser()
    self.getPersonsDiseasesDetail()
    self.getStats()
    self.getTopics()
    self.getCallLogs()
  },
  methods: {
    onClose () {
      this.$router.push({ path: '/doctor/patient-diseases' })
    },
    async getPersonsDiseasesDetail () {
      let self = this
      try {
        await self.$rf
          .getRequest('DoctorRequest')
          .getPersonsDiseasesDetail(self.$route?.params?.id)
          .then((res) => {
            if (res && res.data) {
              self.disease = res.data
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    async getCallLogs () {
      let self = this
      self.current_page = parseInt(self.$route.query.page) || 1
      let params = {
        person_diseases_id: self.$route?.params?.id,
        user_id: this.user_id,
        limit: 13,
        sort: 'updated_at',
        sort_by: 'desc',
        page: self.current_page
      }
      try {
        await self.$rf
          .getRequest('DoctorRequest')
          .getCallLogs(params)
          .then((res) => {
            if (res && res.data) {
              self.call_logs = res.data
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    async getTopics () {
      let self = this
      self.loading = true
      let clinic_id = this.$clinicId
      self.current_page = parseInt(self.$route.query.page) || 1
      let params = {
        page: self.current_page,
        limit: 10,
        sort: 'updated_at',
        sort_by: 'desc',
        types: [6, 7, 8, 10, 12],
        receiver_clinic_id: clinic_id
      }
      try {
        await self.$rf
          .getRequest('DoctorRequest')
          .getTopics(self.$route?.params?.id, params)
          .then((res) => {
            if (res && res.data) {
              self.topics = res.data
            }
          })
      } catch (e) {
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    showModalAppt (is_show) {
      this.$router.push({
        path: `/doctor/appointment-create?disease_id=${this.$route?.params?.id}`
      })
    },
    onRefresh () {
      this.is_renew = true
      this.getTopics()
      setTimeout(() => {
        this.is_renew = false
      }, 500)
    },
    goToCreate () {
      this.$router.push({
        path: `/doctor/diseases/${this.$route?.params?.id}/topic/create`
      })
    },
    async getStats () {
      let self = this
      let params = {}
      self.monitor_stats = []
      await self.$rf
        .getRequest('DoctorRequest')
        .getSurvivalStatSetting(self.$route?.params?.id, params)
        .then((resp) => {
          if (resp && resp.data && resp.data.count) {
            let stats = resp.data.data
            stats.forEach(function (stat, index) {
              if (stat.is_monitor === 1) {
                self.monitor_stats.push(stat)
              }
            })
          }
        })
    },
    getIsFrom (log) {
      let self = this
      if (log) {
        return self.user?.id === log.from_user_id
      }
    },
    formatDMY (date_time) {
      return window.moment(date_time).format('DD/MM/YY HH:mm')
    },
    goToCallLog () {
      this.$router.push({
        path: `/doctor/diseases/${this.$route?.params?.id}/call-logs`
      })
    }
  }
}
</script>
<style lang="css" scoped>
.btn-type-date {
  min-width: 95px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-right: 1px solid;
}

.vc-container {
  border: 0 !important;
}

a:hover {
  text-decoration: none;
}

.border-bottom-width-2 {
  border-bottom: solid 1.5px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}
.wd-180 {
  width: 180px;
}
.left-side-item {
  width: 56px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.right-side-item {
  width: calc(100% - 56px);
}

.icon--item {
  width: 40px;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 50%;
}

.bg-fa {
  background-color: #fafafa;
}
.text-concat {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bg-bold-item {
  background-color: #e7eaf5;
}
</style>