<template>
    <div class="container-window mt-3 mr-3 border bd-e4 px-3">
        <HeaderWindow :title="'Tạo mới hồ sơ sức khỏe'" @onClose="onClose"></HeaderWindow>
        <div class="mt-3 px-3">
            <InformationForm :person="person" :edit="true"></InformationForm>
        </div>
    </div>
</template>
<script>
import InformationForm from '../../components/Diseases/InfomationForm.vue'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
export default {

  name: 'DiseasesCreate',
  components: { HeaderWindow, InformationForm },
  data () {
    return {
      person: null
    }
  },
  mounted () {
    if (this.$user.doctor.is_verified !== 4) {
      this.$toast.open({
        message: 'Bạn cần xác thực tài khoản trước khi tạo hồ sơ!',
        type: 'warning'
      })
      this.$router.push({name: 'DoctorSetting'})
    } else {
      this.getPersonDetail()
    }
  },
  methods: {
    onClose () {
      this.$router.push({ path: `/doctor/patient-diseases` }).catch(_ => {})
    },
    async getPersonDetail () {
      let self = this
      try {
        await self.$rf.getRequest('DoctorRequest').getPersonDetail(self.$route?.params?.id).then(res => {
          self.person = res.data
        }).then(() => {
        })
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
<style lang="css" scoped>
</style>