<template>
  <div class="bg-white border bd-e4 px-3">
    <HeaderWindow @onClose="onClose" :title="title" :show_calll="true" />
    <div class="bg-white pt-3">
      <PersonProfile
        v-if="disease"
        :person="disease && disease.person"
        :monitor_stats="monitor_stats"
        :disease="disease"
      ></PersonProfile>
    </div>
    <TypeBar
      v-if="disease"
      :showing="show"
      :person_id="person_id"
      :disease="disease"
    />
    <PersonalHealthInformation></PersonalHealthInformation>
  </div>
</template>
<script>
import PersonProfile from '../../components/Diseases/PersonProfile'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import TypeBar from '../../components/Diseases/TypeBar.vue'
import PersonalHealthInformation from '../../components/Diseases/PersonalHealthInformation.vue'
import i18n from '../../lang'
export default {
  name: 'DiseasesHealthInformation',
  components: {
    PersonProfile,
    HeaderWindow,
    TypeBar,
    PersonalHealthInformation
  },
  data () {
    return {
      show: 'health-information-listing',
      title: i18n.t('obj_health_records.lbl_personal_health_information'),
      person_id: null,
      person: null,
      is_renew: false,
      disease: null,
      diseases_id: '',
      monitor_stats: null,
      topics: [],
      loading: false,
      current_page: 1
    }
  },
  watch: {
    '$route.query.t': {
      handler: function (t) {
        if (!t) return
        this.getPersonsDiseasesDetail()
        this.getStats()
        this.getTopics()
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    let self = this
    self.diseases_id = self.$route?.params?.id
    self.getPersonsDiseasesDetail()
    self.getStats()
    self.getTopics()
  },
  methods: {
    onClose () {
      this.$router.push({ path: '/doctor/patient-diseases' })
    },
    async getPersonsDiseasesDetail () {
      let self = this
      try {
        await self.$rf
          .getRequest('DoctorRequest')
          .getPersonsDiseasesDetail(self.$route?.params?.id)
          .then((res) => {
            if (res && res.data) {
              self.disease = res.data
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    async getTopics () {
      let self = this
      self.loading = true
      self.current_page = parseInt(self.$route.query.page) || 1
      let params = {
        page: self.current_page,
        limit: 10,
        sort: 'updated_at',
        sort_by: 'desc',
        types: [6, 7, 8, 10, 12]
      }
      try {
        await self.$rf
          .getRequest('DoctorRequest')
          .getTopics(self.$route?.params?.id, params)
          .then((res) => {
            if (res && res.data) {
              self.topics = res.data
            }
          })
      } catch (e) {
        console.log(e)
      } finally {
        self.loading = false
      }
    },
    onRefresh () {
      this.is_renew = true
      this.showModalAppt(false)
      this.getTopics()
      setTimeout(() => {
        this.is_renew = false
      }, 500)
    },
    goToCreate () {
      this.$router.push({
        path: `/doctor/diseases/${this.$route?.params?.id}/topic/create`
      })
    },
    async getStats () {
      let self = this
      let params = {}
      self.monitor_stats = []
      await self.$rf
        .getRequest('DoctorRequest')
        .getSurvivalStatSetting(self.$route?.params?.id, params)
        .then((resp) => {
          if (resp && resp.data && resp.data.count) {
            let stats = resp.data.data
            stats.forEach(function (stat, index) {
              if (stat.is_monitor === 1) {
                self.monitor_stats.push(stat)
              }
            })
          }
        })
    }
  }
}
</script>
<style lang="css" scoped>
.btn-type-date {
  min-width: 95px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-right: 1px solid;
}

.vc-container {
  border: 0 !important;
}

a:hover {
  text-decoration: none;
}

.border-bottom-width-2 {
  border-bottom: solid 1.5px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}
.wd-180 {
  width: 180px;
}
</style>