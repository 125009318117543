<template>
  <div class="bg-white border bd-e4 px-3">
    <HeaderWindow @onClose="onClose" :title="title" :show_calll="true" />
    <div class="bg-white pt-3">
      <PersonProfile
        v-if="person"
        :person="person"
        :disease="disease"
        @showModalAppt="showModalAppt"
        :monitor_stats="monitor_stats"
      ></PersonProfile>
    </div>
    <TypeBar
      :showing="show"
      @changeType="changeType"
      :person_id="person_id"
      :disease="disease"
    />
    <div>
      <div class="mb-3">
        <div class="type-date d-flex flex-column flex-md-row">
          <a
            href="javascript:"
            @click="onChangeTypeDate(1)"
            style="border: none"
            class="btn-type-date robo-16-400"
            :class="
              type_date === 1 ? 'text-white bg-pri' : 'txt-pri bg-lavender'
            "
          >
            {{ $t("obj_health_records.fm_week", { week: 1 }) }}</a
          >
          <a
            href="javascript:"
            @click="onChangeTypeDate(2)"
            style="border: none"
            class="btn-type-date robo-16-400"
            :class="
              type_date === 2 ? 'text-white bg-pri' : 'txt-pri bg-lavender'
            "
          >
            {{ $t("obj_health_records.fm_week", { week: 2 }) }}
          </a>
          <a
            href="javascript:"
            @click="onChangeTypeDate(3)"
            style="border: none"
            class="btn-type-date robo-16-400"
            :class="
              type_date === 3 ? 'text-white bg-pri' : 'txt-pri bg-lavender'
            "
          >
            {{ $t("obj_health_records.fm_week", { week: 3 }) }}
          </a>
          <a
            href="javascript:"
            @click="onChangeTypeDate(4)"
            style="border: none"
            class="btn-type-date robo-16-400"
            :class="
              type_date === 4 ? 'text-white bg-pri' : 'txt-pri bg-lavender'
            "
          >
            {{ $t("obj_health_records.fm_week", { week: 4 }) }}
          </a>
          <a
            href="javascript:"
            class="btn-type-date robo-16-400 border-right-0 px-2 text-white bg-pri"
            >{{ getDateString(dates.start, dates.end) }}</a
          >
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-lg-4 pr-3">
          <div class="border mb-3">
            <div class="bg-lavender p-2 d-flex justify-content-between">
              <p class="mb-0 robo-20-500 txt-pri-dark">
                {{ $t("obj_health_records.lbl_schedule") }}
              </p>
              <p class="mb-0 robo-20-500 txt-pri-dark">
                {{ getDateString(dates.start, dates.end) }}
              </p>
            </div>
            <v-date-picker
              v-model="dates"
              is-range
              @input="onChange()"
              :max-date="new Date()"
              is-expanded
            />
          </div>
          <!-- <div class="border mb-3">
                        <div class="bg-lavender p-2 d-flex justify-content-between">
                            <p class="mb-0 robo-20-500 txt-pri-dark">
                                Lịch hẹn sắp tới
                            </p>
                        </div>
                        <div class="px-3 py-2">
                            <div v-for="appt in appointments.data" class="appt-item" @click="goToApptDetail(appt.id)" :key="appt.id">
                                <div class="w-50 d-flex align-items-center">
                                    <img src="../../../public/assets/images/icon/green-walking.svg" width="20" height="20" v-if="appt.method == 1">
                                    <img src="../../../public/assets/images/icon/big-blue-video.svg" width="20" height="20" v-if="appt.method == 2">
                                    <p class="mb-0 robo-14-400 txt-pri ml-3">{{appt.method === 2 ? 'Cuộc gọi video' : 'Tại cơ sở y tế'}}</p>
                                </div>
                                <div class="w-50 pl-2 text-right">
                                    <p class="mb-0 robo-14-400 txt-pri">{{formatDateHMnDMY(appt.start_time)}}</p>
                                </div>
                                <div class="w-100 pl-4 robo-14-400 txt-pri"><span class="ml-1">{{appt && appt.person_name}}</span> <span v-if="appt">{{person_id === appt.person_id ? '' : ' (Người thân)'}}</span></div>
          </div>-->
          <!-- <div v-if="!can('view-appt')">
                                <p class="mb-0 py-2 text-danger robo-12-400 text-center">Bạn không có quyền xem danh sách này</p>
          </div>-->
          <!-- <div class="d-flex justify-content-center align-items-center mt-3" v-if="can('create-new-appt')">
                                <a href="javascript:;" @click="showModalAppt(true)" class="mb-1 robo-14-500 txt-pri">+ Tạo lịch hẹn</a>
                            </div>
                        </div>
          </div>-->
          <div class="border mb-3">
            <div class="bg-lavender p-2 d-flex justify-content-between">
              <p class="mb-0 robo-20-500 txt-pri-dark">
                {{ $t("obj_health_records.lbl_activity_log") }}
              </p>
            </div>
            <div class="p-3" v-if="disease">
              <ActivityTableMini
                :topics="topics"
                :person_id="person_id"
                :loading="loading_topic"
                @onRefresh="getTopics"
                :hide_pagi="true"
              ></ActivityTableMini>
              <!-- <div class="d-flex justify-content-between align-items-center">
                                <p class="mb-1 robo-12-500 txt-pri">Việc chăm sóc viêm loét họng miệng cho Bn ung thư và đặc biệt là sau xạ trị khá phức tạp. Cần phối hợp nhiều phương pháp, và thự hiện đúng theo hướng dẫn của Bác sĩ.</p>
              </div>-->
              <!--                             <div class="d-flex justify-content-center align-items-center">
                                <a href="javascript:;" class="mb-1 robo-12-500 txt-pri">Kéo trong 3 tháng tới</a>
              </div>-->
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-8">
          <div class="row">
            <div class="col-lg-6 pr-3 mb-1">
              <div class="border mb-3" v-if="disease">
                <div class="bg-lavender p-2 d-flex justify-content-between">
                  <p class="mb-0 robo-20-500 txt-pri-dark">
                    {{ $t("obj_health_records.lbl_health_information") }}
                  </p>
                  <p class="mb-0 robo-20-500 txt-pri-dark"></p>
                </div>
                <div class="p-3" v-if="disease.diseases_current_cure">
                  <div class="mb-2">
                    <p
                      class="mb-0 robo-14-400 txt-pri"
                      style="white-space: pre-wrap"
                    >
                      {{ disease.diseases_current_cure }}
                    </p>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6"></div>
                    <div class="col-6 text-right">
                      <!-- <a href="javascript:;" class="mb-0 robo-12-400 txt-pri bd-pri border-bottom-width-2">Dữ liệu</a> -->
                    </div>
                  </div>
                </div>
                <div
                  v-if="!disease.diseases_current_cure"
                  class="d-flex justify-content-center align-items-center"
                >
                  <img
                    src="../../../public/assets/images/medicine-monitor-blank.png"
                    class="relative"
                  />
                  <button
                    class="absolute btn-l radius-0 bg-pri bd-pri text-white"
                    @click="goToUpdateHI()"
                  >
                    {{ $t("obj_health_records.lbl_update") }}
                  </button>
                </div>
              </div>
              <div class="border mb-3">
                <div
                  class="bg-lavender p-2 d-flex justify-content-between align-items-center"
                >
                  <p class="mb-0 robo-20-500 txt-pri-dark">
                    {{ $t("obj_health_records.lbl_medication_compliance") }}
                  </p>
                  <a
                    href="javascript:;"
                    class="mb-0 robo-14-500 txt-pri bd-pri border-bottom-width-2"
                    @click="onGoToSchedule()"
                  >
                    {{ $t("obj_health_records.lbl_data") }}</a
                  >
                </div>
                <div class="p-3" v-if="medicines && medicines.total">
                  <div
                    v-for="medicine in medicines.data"
                    class="row mb-2"
                    :key="medicine.id"
                  >
                    <div class="col-6">
                      <p
                        class="mb-0 robo-14-400 txt-pri"
                        v-if="medicine.medicine"
                      >
                        {{ medicine.medicine ? medicine.medicine.name : "" }}
                      </p>
                      <p class="mb-0 robo-14-400 txt-pri">
                        {{ medicine ? medicine.name : "" }}
                      </p>
                    </div>
                    <div class="col-6 text-right">
                      <p class="mb-0 robo-14-400 txt-pri">
                        {{
                          !medicine.person_medicine_schedule ||
                          !medicine.person_medicine_schedule.length
                            ? $t("obj_health_records.lbl_not_drank_yet")
                            : ""
                        }}
                        <span
                          v-if="
                            medicine.person_medicine_schedule &&
                            medicine.person_medicine_schedule.length
                          "
                        >
                          <span class="text-red"
                            >{{
                              getMediTimes(medicine.person_medicine_schedule)
                            }}
                            /
                            {{
                              parseInt(medicine.freq_per_day) *
                              parseInt(medicine.person_medicine_schedule.length)
                            }}</span
                          >
                          {{ $t("obj_health_records.lbl_time") }}
                        </span>
                      </p>
                    </div>
                  </div>
                  <!-- <div class="row mb-2">
                                        <div class="col-6">
                                        </div>
                                        <div class="col-6 text-right">

                                        </div>
                  </div>-->
                </div>
                <div
                  v-if="!medicines || !medicines.total"
                  class="d-flex justify-content-center align-items-center"
                >
                  <img
                    src="../../../public/assets/images/medicine-monitor-blank.png"
                    class="relative"
                  />
                  <button
                    class="absolute btn-l radius-0 bg-pri bd-pri text-white"
                    @click="createPrescription()"
                  >
                    {{ $t("obj_health_records.lbl_add_medicine") }}
                  </button>
                </div>
              </div>
              <div class="border mb-3">
                <div class="bg-lavender p-2 d-flex justify-content-between">
                  <p class="mb-0 robo-20-500 txt-pri-dark">
                    {{ $t("obj_health_records.lbl_test_info") }}
                  </p>
                  <p class="mb-0 robo-20-500 txt-pri-dark"></p>
                </div>
                <div v-if="list_tests && list_tests.count" class="p-3">
                  <div class="row mb-2">
                    <div class="col-5">
                      <p class="mb-0 robo-14-500 txt-pri">
                        {{ $t("obj_health_records.lbl_quotient") }}
                      </p>
                    </div>
                    <div class="col-7">
                      <div class="row">
                        <div class="col-7">
                          <div class="row">
                            <div class="col-6 text-center">
                              <div>
                                <p class="robo-14-500 mb-0 txt-pri">
                                  {{ $t("obj_health_records.lbl_result") }}
                                </p>
                              </div>
                            </div>
                            <div class="col-6 text-center">
                              <p class="robo-14-500 mb-0 txt-pri">
                                {{ $t("obj_health_records.lbl_unit") }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-5 text-center">
                          <p class="robo-14-500 mb-0 txt-pri">
                            {{ $t("obj_health_records.lbl_normal") }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-for="test in list_tests.data"
                    class="row mb-2"
                    :key="test.id"
                  >
                    <div class="col-5">
                      <p class="mb-0 robo-14-500 txt-pri">
                        {{ test.test_name }}
                      </p>
                    </div>
                    <div class="col-7">
                      <div class="row">
                        <div class="col-7">
                          <div class="row">
                            <div class="col-6 text-center">
                              <div
                                :set="
                                  (status_test_value = getStatusTestValue(
                                    test.lower_threshold,
                                    test.upper_threshold,
                                    test.test_value
                                  ))
                                "
                              >
                                <p
                                  v-if="status_test_value"
                                  class="robo-14-500 mb-0"
                                  :class="status_test_value.class"
                                >
                                  {{ roundToFive(test.test_value)
                                  }}{{ status_test_value.text }}
                                </p>
                              </div>
                            </div>
                            <div class="col-6 text-center">
                              <p class="robo-14-400 mb-0 txt-pri">
                                {{ test.test_unit }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-5 text-center">
                          <p class="robo-14-400 mb-0 txt-pri">
                            {{ roundToFive(test.lower_threshold) }}-{{
                              roundToFive(test.upper_threshold)
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-for="result in list_results.data"
                    class="row mb-2"
                    :key="result.id"
                  >
                    <div class="col-5">
                      <p class="mb-0 robo-14-500 txt-pri">
                        {{ result.exploration_name }}
                      </p>
                    </div>
                    <div class="col-7">
                      <p class="mb-0 robo-14-400 txt-pri">
                        {{ result.exploration_result }}
                      </p>
                    </div>
                  </div>
                  <div class="row mb-2" v-if="list_tests || list_results">
                    <div class="col-12 text-right">
                      <a
                        href="javascript:;"
                        class="mb-0 robo-14-500 txt-pri border-bottom-1 bd-pri"
                        @click="goToTest()"
                        >{{ $t("obj_health_records.lbl_detail") }}</a
                      >
                    </div>
                  </div>
                  <!-- <div class="row mb-2">
                                        <div class="col-6">
                                        </div>
                                        <div class="col-6 text-right">
                                            <a href="javascript:;" class="mb-0 robo-12-400 txt-pri bd-pri border-bottom-width-2">Dữ liệu</a>
                                        </div>
                  </div>-->
                </div>
                <div v-if="!list_tests || !list_tests.count">
                  <img
                    src="../../../public/assets/images/list-2-blur.png"
                    alt
                  />
                </div>
                <!-- <div class="d-flex justify-content-center align-items-center">
                                    <img src="../../../public/assets/images/medicine-monitor-blank.png" class="relative">
                                    <button class="absolute btn-l radius-0 bg-pri bd-pri text-white" @click="gotoMedicines()">Thêm thuốc</button>
                </div>-->
              </div>
              <div class="border mb-3" :set="(has_value = symptomHasValue())">
                <div class="bg-lavender p-2 d-flex justify-content-between">
                  <p class="mb-0 robo-20-500 txt-pri-dark">
                    {{ $t("obj_health_records.lbl_abnormal_signs") }}
                  </p>
                  <!-- <a class="mb-0 robo-14-400 txt-pri bd-pri border-bottom-width-2" href="javascript:;">
                                        Xem tất cả
                  </a>-->
                </div>
                <div class="p-3" v-if="has_value">
                  <div
                    v-for="symp in symptoms.data"
                    class="row mb-3"
                    v-show="
                      symp.person_symptom_value &&
                      symp.person_symptom_value.length &&
                      valueValid(symp.person_symptom_value)
                    "
                    :key="symp.id"
                  >
                    <div class="col-6">
                      <p class="mb-0 robo-14-400 txt-pri mr-1">
                        {{ symp.symptom_name }}
                      </p>
                      <!-- <img v-if="value && value.has_symptom" width="20" height="20" src="../../../public/assets/images/icon/info-circle-red.svg" alt="" /> -->
                      <!-- <img width="20" height="20" src="../../../public/assets/images/icon/icon-info-yellow.svg" alt="" /> -->
                    </div>
                    <div class="col-6 text-right">
                      <p
                        v-for="value in symp.person_symptom_value"
                        v-show="value.has_symptom === 1"
                        class="mb-1 robo-14-400 txt-pri mr-1"
                        :key="value.id"
                      >
                        {{ formatDateDMY(value.created_at) }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  v-if="!has_value"
                  class="d-flex justify-content-center align-items-center"
                >
                  <img
                    src="../../../public/assets/images/list-2-blur.png"
                    class="relative"
                  />
                  <!-- <button class="absolute btn-l radius-0 bg-pri bd-pri text-white">Thêm dữ liệu gần</button> -->
                </div>
              </div>
            </div>
            <div class="col-lg-6 pr-3 mb-1">
              <div
                v-for="stat in monitor_stats"
                :set="(chart = getChartData(stat))"
                :key="stat.id"
              >
                <div class="border mb-3">
                  <div
                    class="bg-lavender p-2 d-flex justify-content-between align-items-center"
                  >
                    <p class="mb-0 robo-20-500 txt-pri-dark">
                      {{ getStatName(stat) }}
                      <span v-if="stat.unit">({{ getStatUnit(stat) }})</span>
                    </p>
                    <button
                      v-if="chart && chart.total_data"
                      class="btn robo-16-500 radius-0 bg-pri bd-pri text-white"
                      @click="showModalStats(true)"
                    >
                      {{ $t("obj_health_records.lbl_add_data") }}
                    </button>
                  </div>
                  <div>
                    <div style="overflow-x: auto; cursor: pointer">
                      <div v-if="chart && chart.total_data">
                        <LineChart
                          :style="chart.css"
                          :chart-data="chart.datas"
                          :options="chart.option"
                        ></LineChart>
                        <!-- <div class="d-flex justify-content-end">
                                                <div class="btn-full-screen-chart" @click="onShowFullScreenChart(getChartData(stat), stat.name)">
                                                    <img src="../../../public/assets/images/icon/full-screen.svg" width="24" height="24" alt="">
                                                </div>
                        </div>-->
                      </div>
                      <div
                        v-if="chart && !chart.total_data"
                        class="d-flex justify-content-center align-items-center"
                      >
                        <img
                          src="../../../public/assets/images/chart-blur.png"
                          class="relative"
                        />
                        <button
                          class="absolute btn-l radius-0 bg-pri bd-pri text-white"
                          @click="showModalStats(true)"
                        >
                          {{ $t("obj_health_records.lbl_add_data_resent") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalAppointment
      v-if="!!person && !!disease"
      @onRefresh="refreshAppt"
      :disease="disease"
      :root_screen="'Diseases Patient Profile Overview'"
      :screen="'Diseases Patient Profile Overview'"
    ></ModalAppointment>
    <ModalSurvivalStatsValues
      v-if="person"
      :monitor_stats="monitor_stats"
      @onSubmit="onSubmitStats"
      :name="person.name"
    ></ModalSurvivalStatsValues>
    <!-- <ModalFullLine :name="chart_name" :chart_data="slt_chart"></ModalFullLine> -->
    <ModalAddPrescription
      ref="ModalAddPrescription"
      @refresh="getPersonMedicine"
    />
  </div>
</template>
<script>
import PersonProfile from '../../components/Diseases/PersonProfile.vue'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import ModalSurvivalStatsValues from '../../components/Diseases/ModalSurvivalStatsValues.vue'
import TypeBar from '../../components/Diseases/TypeBar.vue'
import LineChart from '../../utils/LineChart.js'
import ModalAppointment from '../../components/Appointment/ModalAppointment'
import ModalFullLine from '../../components/Chart/ModalFullLine.vue'
import ActivityTableMini from '../../components/Diseases/ActivityTableMini'
import moment from 'moment'
import ModalAddPrescription from './Prescription/ModalAdd.vue'

export default {
  name: 'DiseasesOverview',
  components: {
    PersonProfile,
    HeaderWindow,
    TypeBar,
    LineChart,
    ModalAppointment,
    ActivityTableMini,
    ModalSurvivalStatsValues,
    ModalFullLine,
    ModalAddPrescription
  },
  data () {
    return {
      is_refresh: false,
      type_date: null,
      dates: {
        start: new Date(),
        end: new Date()
      },
      survival_stats: [],
      chartData: {
        labels: ['12/7', '13/7', '14/7', '15/7', '16/7', '17/7'],
        datasets: [
          {
            label: 'Blue',
            backgroundColor: 'transparent',
            data: [3, 7, 4, 3, 7, 4],
            borderColor: '#20409B',
            borderWidth: 1,
            pointBackgroundColor: 'white',
            pointBorderColor: '#20409B',
            lineTension: 0
          }
        ]
      },
      options: {
        bezierCurve: true,
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                display: true,
                min: 0,
                max: 10
              },
              gridLines: {
                display: true
              }
            }
          ]
        }
      },
      type: 1,
      show: 'overview',
      title: this.$t('obj_health_records.lbl_health_information'),
      person_id: null,
      person: null,
      medicines: [],
      symptoms: [],
      danger_symptoms: [],
      person_symptom_slt: null,
      disease: null,
      monitor_stats: [],
      histories_stats: [],
      appointments: [],
      list_tests: [],
      list_results: [],
      chart_name: '',
      slt_chart: null,
      topics: [],
      loading_topic: false
    }
  },
  watch: {
    '$route.query.t': {
      handler: function (t) {
        if (!t) return
        this.getPersonsDiseases('all')
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    let self = this
    self.getPersonsDiseases('all')
    // setTimeout(() => {
    //   self.sendAmplitude('Create Cancer Patient', {
    //     'doctor id': user && user.doctor && user.doctor.id ? user.doctor.id : '',
    //     'doctor name': user && user.name ? user.name : '',
    //     'workspace id': clinic && clinic.id ? clinic.id : '',
    //     'workspace name': clinic && clinic.name ? clinic.name : '',
    //     'timestamp': window.moment(new Date()).format('HH:mm DD-MM-YYYY')
    //     // 'patient id': data ? data : '',
    //     // 'patient name': ''
    //   })
    // }, 300)
    window.$(document).ready(function () {
      window.$('#modal-line-chart').on('hidden.bs.modal', function () {
        self.chart_name = ''
        self.slt_chart = null
      })
    })
  },
  methods: {
    isDisplayChart (code) {
      return (
        !!this.monitor_stats &&
        !!this.monitor_stats.length &&
        !!this.monitor_stats.find((stat) => stat.code === code)
      )
    },
    async getStats () {
      let self = this
      let params = {}
      self.monitor_stats = []
      await self.$rf
        .getRequest('DoctorRequest')
        .getSurvivalStatSetting(self.$route?.params?.id, params)
        .then((resp) => {
          if (resp && resp.data && resp.data.count) {
            let stats = resp.data.data
            stats.forEach(function (stat, index) {
              if (stat.is_monitor === 1) {
                self.monitor_stats.push(stat)
              }
            })
          }
        })
    },
    async onSubmitStats (data) {
      let self = this
      let params = {
        values: data
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .updatePersonStatsV2(self.person_id, params)
        .then(() => {
          self.showModalStats(false)
          self.onRefreshStats()
        })
        .catch((e) => {
          console.log(e)
        })
    },
    async getHistoriesStats () {
      let self = this
      self.histories_stats = []
      let params = {
        from: self.formatDateDMYMi(self.dates.start),
        to: self.formatDateDMYMi(self.dates.end),
        sort: 'created_at',
        sort_by: 'asc',
        get_all: 1
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .getPersonStatHistories(self.person_id, params)
        .then((resp) => {
          if (resp && resp.data && resp.data.length) {
            self.histories_stats = resp.data
            let data = {}
            resp.data.forEach((s) => {
              data[s.code] = s
            })
            console.log(data)
          }
        })
    },
    async getTestsByDiseases () {
      let self = this
      try {
        var params = {
          limit: 1000,
          is_pass: 1,
          from: self.formatDateHMDMY(self.dates.start),
          to: self.formatDateHMDMY(self.dates.end)
        }
        await self.$rf
          .getRequest('DoctorRequest')
          .getTestsByDiseases(self.$route?.params?.id, params)
          .then((res) => {
            if (res && res.data) {
              self.list_tests = res.data
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    async getResultsByDiseases () {
      let self = this
      try {
        var params = {
          limit: 3,
          from: self.formatDateHMDMY(self.dates.start),
          to: self.formatDateHMDMY(self.dates.end)
        }
        await self.$rf
          .getRequest('DoctorRequest')
          .getResultsByDiseases(self.$route?.params?.id, params)
          .then((res) => {
            if (res && res.data) {
              self.list_results = res.data
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    onClose () {
      this.$router.push({ path: '/doctor/patient-diseases' })
    },
    goToTest () {
      this.$router.push({
        path: `/doctor/diseases/${this.$route?.params?.id}/test-info`
      })
    },
    goToApptDetail (id) {
      this.$router.push({ path: `/doctor/appts/${id}` })
    },
    onGoToSchedule () {
      this.$router.push({
        path: `/doctor/diseases/${this.$route?.params?.id}/medicine-schedules`
      })
    },
    getChartData (stat) {
      let code = stat.code
      let name = stat.name
      let self = this
      let labels = []
      let datas = []
      let datas2 = []
      let css = {
        position: 'relative',
        background: '#fff',
        'padding-top': '30px',
        'border-radius': '12px'
      }
      self.histories_stats.forEach(function (el, idx) {
        if (el.value && el.code === code) {
          if (code === 'huyet_ap') {
            let value = el.value ? el.value.split('/') : []
            let first = value && value.length && value[0] ? value[0] : 0
            let second = value && value.length && value[1] ? value[1] : 0
            datas.push(first)
            datas2.push(second)
          } else {
            datas.push(el.value ? el.value : 0)
          }
          labels.push(self.formatDateDM(el.created_at))
        }
      })
      let chartData = {}
      if (code === 'huyet_ap') {
        chartData = {
          labels: labels,
          datasets: [
            {
              label: 'Tâm thu',
              backgroundColor: 'transparent',
              data: datas,
              borderColor: '#20409B',
              borderWidth: 1,
              pointBackgroundColor: 'white',
              pointBorderColor: '#20409B',
              lineTension: 0
            },
            {
              label: 'Tâm trương',
              backgroundColor: 'transparent',
              data: datas2,
              borderColor: 'red',
              borderWidth: 1,
              pointBackgroundColor: 'white',
              pointBorderColor: 'red',
              lineTension: 0
            }
          ]
        }
      } else {
        chartData = {
          labels: labels,
          datasets: [
            {
              label: name,
              backgroundColor: 'transparent',
              data: datas,
              borderColor: '#20409B',
              borderWidth: 1,
              pointBackgroundColor: 'white',
              pointBorderColor: '#20409B',
              lineTension: 0
            }
          ]
        }
      }
      let min = Math.min(...datas)
      let max = Math.max(...datas)
      if (datas2.length) {
        let min2 = Math.min(...datas2)
        let max2 = Math.max(...datas2)
        if (min2 < min) {
          min = min2
        }
        if (max2 > max) {
          max = max2
        }
      }
      min = min - (min / 100) * 20
      max = max + (max / 100) * 20
      if (max - min > 10) {
        max = Math.round(max / 10) * 10
        if (min > 0) {
          min = Math.round(min / 10) * 10
        }
      }
      let option = {
        bezierCurve: true,
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                display: true,
                min: min >= 0 ? parseInt(min) : 0,
                max: max ? parseInt(max) : 10
              },
              gridLines: {
                display: true
              }
            }
          ]
        }
      }
      if (labels.length > 8) {
        let width = labels.length * 100
        css.width = width + 'px'
      }
      return {
        datas: chartData,
        option: option,
        total_data: datas.length,
        css: css
      }
    },
    renewData (type_renew) {
      let self = this
      if (type_renew === 'all') {
        self.getPersonMedicine()
        self.getPersonSymptom()
        self.getTestsByDiseases()
        self.getResultsByDiseases()
        self.getHistoriesStats()
        self.onRefreshAppt()
        self.getStats()
        self.getTopics()
      }
      if (type_renew === 'appt') {
        self.onRefreshAppt()
        self.getTopics()
      }
      if (type_renew === 'stats') {
        self.getStats()
        self.getHistoriesStats()
      }
    },
    onRefreshStats () {
      this.getPersonsDiseases('stats')
    },
    onRefreshAppt () {
      this.getAppointment()
    },
    refreshAppt () {
      this.renewData('appt')
    },
    async getAppointment () {
      let self = this
      let params = {
        start_time: moment().toDate(),
        limit: 3,
        sort: 'start_time',
        sort_by: 'asc',
        person_diseases_id: self.disease && self.disease.id
        // person_id: self.person_id
      }
      let resp = await self.$rf.getRequest('DoctorRequest').allAppts(params)
      self.appointments = resp.data
    },
    onChangeTypeDate (type) {
      let self = this
      self.type_date = type
      if (type === 1) {
        self.dates = {
          start: window.moment().startOf('week').toDate(),
          end: window.moment().toDate()
        }
      }
      if (type === 2) {
        self.dates = {
          start: window.moment().startOf('week').subtract(7, 'days').toDate(),
          end: window.moment().toDate()
        }
      }
      if (type === 3) {
        self.dates = {
          start: window.moment().startOf('week').subtract(14, 'days').toDate(),
          end: window.moment().toDate()
        }
      }
      if (type === 4) {
        self.dates = {
          start: window.moment().startOf('week').subtract(21, 'days').toDate(),
          end: window.moment().toDate()
        }
      }
      setTimeout(() => {
        self.renewData('all')
      }, 200)
    },
    onChange () {
      this.renewData('all')
    },
    async getPersonsDiseases (type_renew) {
      let self = this
      if (!self.$route?.params?.id) return
      try {
        await self.$rf
          .getRequest('DoctorRequest')
          .getPersonsDiseasesDetail(self.$route?.params?.id)
          .then((res) => {
            self.disease = res.data
            let person = res.data.person || null
            self.person_id = person ? person.id : null
            self.person = person || null
            setTimeout(() => {
              self.renewData(type_renew, self.person_id)
            }, 100)
          })
          .then(() => {})
      } catch (e) {
        console.log(e)
        this.$router.push({ path: '/doctor/patient-diseases' })
      }
    },
    async getPersonMedicine () {
      let self = this
      let params = {
        from: self.formatDateHMDMY(self.dates.start),
        to: self.formatDateHMDMY(self.dates.end),
        is_monitor: 1,
        has_schedule: 1,
        person_diseases_id: self.disease && self.disease.id
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .getPersonMedicine(self.person_id, params)
        .then((resp) => {
          self.medicines = resp.data
        })
        .catch((e) => {
          console.log(e)
        })
    },
    async getPersonSymptom (is_danger = false, is_monitor = false) {
      let self = this
      let params = {
        from: self.formatDateHMDMY(self.dates.start),
        to: self.formatDateHMDMY(self.dates.end),
        person_diseases_id: self.disease && self.disease.id
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .getPersonSymptom(self.person_id, params)
        .then((resp) => {
          self.symptoms = resp.data
        })
        .catch((e) => {
          console.log(e)
        })
    },
    async getPersonDetail () {
      let self = this
      await self.$rf
        .getRequest('DoctorRequest')
        .getPersonDetail(self.person_id)
        .then((resp) => {
          self.person = resp.data
        })
        .catch((e) => {
          console.log(e)
        })
    },
    async getTopics () {
      let self = this
      if (!self.$route?.params?.id) return
      self.loading_topic = true
      self.current_page = parseInt(self.$route.query.page) || 1
      let params = {
        page: self.current_page,
        limit: 3,
        sort: 'updated_at',
        sort_by: 'desc',
        types: [6, 7, 8, 10, 12]
      }
      try {
        await self.$rf
          .getRequest('DoctorRequest')
          .getTopics(self.$route?.params?.id, params)
          .then((res) => {
            if (res && res.data) {
              self.topics = res.data
            }
          })
      } catch (e) {
        console.log(e)
      } finally {
        self.loading_topic = false
      }
    },
    getMediTimes (medi_list) {
      let total_use = 0
      medi_list.forEach(function (element, index) {
        if (element.times) {
          total_use += parseInt(element.times)
        }
      })
      return total_use
    },
    getTotalMediTimes (person_medicine) {
      if (
        person_medicine.amount &&
        person_medicine.freq_per_day &&
        person_medicine.amount_per_one
      ) {
        let total =
          parseInt(person_medicine.amount) /
          (parseInt(person_medicine.amount) *
            parseInt(person_medicine.amount_per_one))
        return Math.round(total)
      } else {
        return 0
      }
    },
    getNewestSymptomValue (values) {
      if (values && values.length) {
        return values[values.length - 1]
      }
    },
    getSubNewestSymptomValue (values) {
      if (values && values.length > 1) {
        return values[values.length - 2].value
      }
    },
    symptomHasValue () {
      let self = this
      return (
        self.symptoms &&
        self.symptoms.count &&
        !!self.symptoms.data.filter(
          (symptom) =>
            symptom.person_symptom_value &&
            symptom.person_symptom_value.length &&
            !!symptom.person_symptom_value.filter(
              (value) => value.has_symptom === 1
            ).length
        ).length
      )
    },
    valueValid (values) {
      return !!values.filter((value) => value.has_symptom === 1).length
    },
    isCrossThreshold (min, max, value) {
      if ((min || parseInt(min) === 0) && max && !isNaN(value)) {
        return (
          parseInt(min) >= parseInt(value) || parseInt(value) >= parseInt(max)
        )
      }
    },
    isNearThreshold (min, max, value) {
      if ((min || parseInt(min) === 0) && max && !isNaN(value)) {
        return (
          parseInt(min) * 0.8 >= parseInt(value) ||
          parseInt(value) >= parseInt(max) * 0.8
        )
      }
    },
    getStatusTestValue (lower, upper, value) {
      var params = [
        {
          text: 'L',
          class: 'text-primary'
        },
        {
          text: 'H',
          class: 'text-red'
        }
      ]
      if (value < lower) {
        return params[0]
      } else if (value > upper) {
        return params[1]
      }
    },
    formatDateDMY (date) {
      return window.moment(date).format('DD/MM/YYYY')
    },
    formatDateHMnDMY (date) {
      let string = window.moment(date).format('HH:mm DD/MM/YY')
      return string.replace(' ', ' ngày ')
    },
    formatDateHMDMY (date) {
      return window.moment(date).format('HH:mm DD-MM-YYYY')
    },
    formatDateDMYMi (date) {
      return window.moment(date).format('DD-MM-YYYY')
    },
    getTime (date) {
      return window.moment(date).format('HH:mm')
    },
    formatDateDM (date) {
      return window.moment(date).format('DD/MM')
    },
    sameDate (start, end) {
      let start_time = window.moment(start)
      let end_end = window.moment(end)
      return start_time.isSame(end_end, 'day')
    },
    changeType (type) {
      let self = this
      self.type = type
      if (type === 1) {
        self.show = 'overview'
        self.title = 'Hồ sơ sức khỏe'
      } else if (type === 2) {
        self.show = 'files'
        self.title = 'Tài liệu'
      } else if (type === 3) {
        self.show = 'medicines'
        self.title = 'Thông tin thuốc'
      } else if (type === 4) {
        self.show = 'cases'
        self.title = 'Bệnh sử'
      }
    },
    gotoMedicines () {
      let self = this
      self.$router
        .push({
          path: `/doctor/diseases/${self.disease.id}/medicine-add?person=${self.person_id}`
        })
        .catch((_) => {})
    },
    addNewSymptom (symp) {
      let self = this
      self.person_symptom_slt = symp
      self.showSymptomModal(true)
    },
    roundToFive (num) {
      if (!num) return 0
      return +(Math.round(num + 'e+5') + 'e-5')
    },
    onShowFullScreenChart (chart, name) {
      this.slt_chart = chart
      this.chart_name = name
      setTimeout(() => {
        this.showFullScreenChart(true)
      }, 100)
    },
    showFullScreenChart (is_show) {
      window.$('#modal-line-chart').modal(is_show ? 'show' : 'hide')
    },
    showSymptomModal (is_show) {
      window.$('#modal__symptom').modal(is_show ? 'show' : 'hide')
    },
    showModalAppt (is_show) {
      this.$router.push({
        path: `/doctor/appointment-create?disease_id=${this.$route?.params?.id}`
      })
    },
    showModalStats (is_show) {
      window.$('#modal__stats').modal(is_show ? 'show' : 'hide')
    },
    goToUpdateHI () {
      this.$router
        .push({
          path: `/doctor/diseases/${this.disease.id}/health-information/update`
        })
        .catch((_) => {})
    },
    getDateString (start, end) {
      let is_same = this.sameDate(start, end)

      if (is_same) {
        if (this.sameDate(start, window.moment())) {
          return this.$t('lbl_today')
        } else {
          return this.formatDateDMY(start)
        }
      } else {
        let start_string = this.sameDate(start, window.moment())
          ? this.$t('lbl_today')
          : this.formatDateDMY(start)
        let end_string = this.sameDate(end, window.moment())
          ? this.$t('lbl_today')
          : this.formatDateDMY(end)
        return start_string + ' - ' + end_string
      }
    },
    createPrescription () {
      this.$refs.ModalAddPrescription.openDialog()
    },
    getStatName (stat) {
      const code = stat.code

      return this.$t(
        code === 'weight'
          ? 'obj_health_records.lbl_weight'
          : code === 'height'
            ? 'obj_health_records.lbl_height'
            : code === 'tim_mach'
              ? 'obj_health_records.lbl_circuit'
              : code === 'huyet_ap'
                ? 'obj_health_records.lbl_blood_pressure'
                : code === 'nhip_tho'
                  ? 'obj_health_records.lbl_breathing'
                  : code === 'nhiet_do'
                    ? 'obj_health_records.lbl_body_temperature'
                    : code === 'spo2'
                      ? 'SPO2'
                      : code === 'glucose'
                        ? 'obj_health_records.lbl_blood_glucose'
                        : stat.name
      )
    },
    getStatUnit (stat) {
      const code = stat.code

      return this.$t(
        code === 'weight'
          ? 'Kg'
          : code === 'height'
            ? 'cm'
            : code === 'tim_mach'
              ? 'obj_health_records.lbl_beats_minute'
              : code === 'huyet_ap'
                ? 'mmHg'
                : code === 'nhip_tho'
                  ? 'obj_health_records.lbl_beats_minute'
                  : code === 'nhiet_do'
                    ? 'C°'
                    : code === 'spo2'
                      ? '%'
                      : code === 'glucose'
                        ? stat.unit
                        : stat.unit
      )
    }
  }
}
</script>
<style lang="css" scoped>
.btn-type-date {
  min-width: 95px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-right: 1px solid;
}

.btn-type-date:hover {
  background-color: #c3cae7;
}

.vc-container {
  border: 0 !important;
}

a:hover {
  text-decoration: none;
}

.border-bottom-width-2 {
  border-bottom: solid 1.5px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.blank-stage {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.border-bottom-1 {
  border-bottom: 1px solid;
}

.appt-item {
  background-color: #f6f6f6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 13px;
  cursor: pointer;
  flex-wrap: wrap;
}
.appt-item:hover {
  background-color: #e7eaf5;
}
.btn-full-screen-chart {
  padding: 5px;
  border: 1px solid #bec6e4;
  box-sizing: border-box;
  border-radius: 7px;
  margin: 10px;
  cursor: pointer;
}
</style>
